import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.reports),function(report){return _c(VCard,{key:report.id,staticClass:"feed-tests mt-2",attrs:{"flat":"","outlined":"","to":/reports/+report.id}},[_c(VCardTitle,{staticClass:"pa-3"},[_c(VAvatar,{class:[
          report['overallResult'] ==='PASSED' ? 'pass' : report['overallResult'] ==='FAILED' ? 'fail': null,
        'item-icon' ],attrs:{"rounded":"","size":"64"}},[_c('img',{staticClass:"report-image",attrs:{"src":require("../assets/reportIcon.png"),"alt":"report"}})]),_c('div',{staticClass:"reports-info"},[_c(VRow,[_c(VCol,[_c(VChip,{staticClass:"ml-0 text--secondary",class:[
                report['overallResult'] ==='PASSED' ? 'pass' : report['overallResult'] ==='FAILED' ? 'fail': null ],attrs:{"label":"","x-small":"","pill":""}},[_c('span',[_vm._v(_vm._s(report.overallResult))])])],1),_c(VCol,{directives:[{name:"show",rawName:"v-show",value:(report.testMode === true),expression:"report.testMode === true"}]},[_c(VChip,{attrs:{"x-small":"","pill":"","color":"red","text-color":"darken-4"}},[_vm._v(" Test Report ")])],1)],1),_c(VCardText,{staticClass:"px-0 py-0"},[_c(VListItemTitle,{staticClass:"ml-0 font-weight-medium letter-spacing-normal"},[_c('span',{staticClass:"report-text"},[_vm._v(_vm._s(report.deviceSN))]),_c('br'),_c('span',{staticClass:"date-text"},[_vm._v(_vm._s(new Date(report.testTimestamp * 1000).toLocaleString()))])])],1)],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }