<template>
   <v-row class="fill-height py-0 mt-6">
    <v-col class="pa-0 ma-0  pr-lg-0 d-none d-lg-flex tree-menu-width"
           cols="12"
           lg="2"
           >
      <slot name="first"/>
    </v-col>
     <v-col class="pa-0 feed-list-width"
           cols="12"
           lg="12"
           sm="12"
           md="12"
           >
    <div class="backSearch d-flex justify-space-between">
        <slot name="searchbar" />
      <slot name="sorticon" />
        </div>
        <slot name="second"/>
    </v-col>
    <v-col class="pa-0 d-none d-sm-flex">
      <slot name="third"/>
    </v-col>
  </v-row>



</template>

<script>
export default {

}
</script>

<style>
/*@media only screen and (min-width: 1600px) {*/
/*.tree-menu-width {*/
/*  min-width: 274px;*/
/*  max-width: 274px;*/
/*}*/
/*}*/

@media only screen and (min-width: 1100px) {
.feed-list-width {
  min-width: 400px;
  max-width: 400px;
}
}
@media only screen and (max-width: 1110px) {
.flyout-menu-width {
  min-width: 1082px;
  max-width: 1082px;
}
}

.backSearch {
  background-color: #eee !important;
}
.fill-height {
  height: 107vh !important;
}





</style>
