<template>
  <div :style="{ background: $vuetify.theme.themes.light.general_bckgr, height: '100%' }"
       class="pa-8 report-container w-100"
       v-if="report.id">
    <div class="header d-flex pa-4 flex-fill justify-space-between align-center">
      <div class="d-flex">
        <v-avatar :color="report.overallResult === 'PASSED' ? 'primary' : 'error'" size="64" tile class="icon">
          <v-icon color="#ffffff" size="24">mdi-file-document</v-icon>
        </v-avatar>
        <div class="metadata d-flex flex-column ml-4">
          <div class="d-flex">
            <v-chip :color="report.overallResult === 'PASSED' ? 'primary' : 'error'"
                    class="d-flex white--text text-capitalize" small>
              {{ report.overallResult && report.overallResult.toLowerCase() }}
            </v-chip>
            <v-chip color="lightGrey" class="d-flex text--secondary ml-1" small>Not Installed: {{
                notInstalled.length
              }}
            </v-chip>
          </div>
          <span class="text-h5 font-weight-bold">{{ report.id }}</span>
          <span class="body-2 text--secondary">{{ new Date(report.testTimestamp * 1000).toLocaleString() }}</span>
        </div>
      </div>
      <v-btn icon size="24">
        <v-icon color="text--secondary" @click="closeReport">mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider style="z-index: 99 !important"></v-divider>
    <v-row>
      <v-col md="8" class="pr-4 content-height overflow-y-auto">
        <div class="my-10 report-overview pa-4">
          <span>Report {{ report.id }} has <b>{{ report.overallResult.toLowerCase() }}</b>. It consists of: <br/>
          </span>
          <ul>
            <li v-if="passed.length > 0">
              <b> {{ passed.length }} Passed Tests </b> <span class="text--secondary">({{
                passed.map(p => {
                  return capitalize(p)
                }).join(', ')
              }})</span>
            </li>
            <li v-if="notInstalled.length > 0">
              <b> {{ notInstalled.length }} Not Installed Tests </b> <span class="text--secondary">({{
                notInstalled.map(p => {
                  return capitalize(p)
                }).join(', ')
              }})</span>
            </li>
            <li v-if="failed.length > 0">
              <b> {{ failed.length }} Failed Tests </b> <span class="text--secondary">({{
                failed.map(p => {
                  return capitalize(p)
                }).join(', ')
              }})</span>
            </li>
            <li v-if="skipped.length > 0">
              <b> {{ skipped.length }} Skipped Tests </b> <span class="text--secondary">({{
                skipped.map(p => {
                  return capitalize(p)
                }).join(', ')
              }})</span>
            </li>
          </ul>
        </div>
        <div class="detailed-reports fill-height">
          <v-expansion-panels flat>
            <v-expansion-panel class="panel-border" :disabled="report.results.touchscreen.result === 'Not installed'">
              <v-expansion-panel-header class="text-uppercase font-weight-medium">
                <span>Displays | <span
                    :class="[colors[`${report.results.touchscreen.result}`].text, 'text-capitalize font-weight-regular']">{{
                    report.results.touchscreen.result
                  }} </span><v-icon
                    :color="colors[`${report.results.touchscreen.result}`].color">{{
                    icons[`${report.results.touchscreen.result}`]
                  }}</v-icon> </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-2">
                <span class="grey--text">Detected Displays ({{
                    report.results.touchscreen.displayTouchTest.length
                  }})</span>
                <v-card outlined class="d-flex pa-4 align-start my-2"
                        v-for="(display, i) in report.results.touchscreen.displayTouchTest" v-bind:key="i">
                  <v-icon size="20" class="mt-1">mdi-monitor</v-icon>
                  <div class="d-flex flex-column ml-2">
                    <span class="subtitle-2">Display {{ i + 1 }} | TouchScreen: {{ display.bind }}</span>
                    <span class="caption">{{ display.port }} output</span>
                  </div>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="panel-border" :disabled="report.results.printer.result === 'Not installed'">
              <v-expansion-panel-header class="text-uppercase font-weight-medium">
                <span>Printer | <span
                    :class="[colors[`${report.results.printer.result}`].text, 'text-capitalize font-weight-regular']">{{
                    report.results.printer.result
                  }} </span><v-icon
                    :color="colors[`${report.results.printer.result}`].color">{{
                    icons[`${report.results.printer.result}`]
                  }}</v-icon> </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-2">
                <span class="grey--text">Detected Printers (1)</span>
                <v-card outlined class="d-flex pa-4 align-start my-2">
                  <v-icon size="20" class="mt-1">mdi-printer</v-icon>
                  <div class="d-flex flex-column ml-2">
                    <span class="subtitle-2">{{ report.results.printer.detectedPrinter }}</span>
                    <span class="caption">TBD</span>
                  </div>
                </v-card>
                <span class="float-right caption">S/N: {{ report.results.printer.printerSN }}</span>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="panel-border"
                               :disabled="report.results.barcode.barcodeResult === 'Not installed'">
              <v-expansion-panel-header class="text-uppercase font-weight-medium">
                <span>Scanner | <span
                    :class="[colors[`${report.results.barcode.result}`].text, 'text-capitalize font-weight-regular']">{{
                    report.results.barcode.result
                  }} </span><v-icon
                    :color="colors[`${report.results.barcode.result}`].color">{{
                    icons[`${report.results.barcode.result}`]
                  }}</v-icon> </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-2">
                <span class="grey--text">Detected Scanners (1)</span>
                <v-card outlined class="d-flex pa-4 align-start my-2">
                  <v-icon size="20" class="mt-1">mdi-barcode-scan</v-icon>
                  <div class="d-flex flex-column ml-2">
                    <span class="subtitle-2">Generic Scanner</span>
                    <span class="caption">USB</span>
                  </div>
                </v-card>
                <span class="float-right caption">S/N: {{ report.results.barcode.barcodeSN }}</span>

              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="panel-border">
              <v-expansion-panel-header class="text-uppercase font-weight-medium">
                <span>Sound | <span
                    :class="[colors[`${report.results.sound.result}`].text, 'text-capitalize font-weight-regular']">{{
                    report.results.sound.result
                  }} </span><v-icon
                    :color="colors[`${report.results.sound.result}`].color">{{
                    icons[`${report.results.sound.result}`]
                  }}</v-icon> </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-2">
                <span class="grey--text">Detected Sound Devices (1)</span>
                <v-card outlined class="d-flex pa-4 align-start my-2">
                  <v-icon size="20" class="mt-1">mdi-volume-high</v-icon>
                  <div class="d-flex flex-column ml-2">
                    <span class="subtitle-2">{{ report.results.sound.detectedCard }}</span>
                    <span class="caption">PCI</span>
                  </div>
                </v-card>
                <v-card outlined class="d-flex py-2 px-4 align-center my-2 ml-2">
                  <div class="d-flex align-center">
                    <v-icon size="20" class="mt-1">mdi-ear-hearing</v-icon>
                    <span
                        :class="['subtitle-2', 'ml-2', {'text--disabled': report.results.sound.leftSpeakerResult === 'Not installed'}]">Left Speaker Test | {{
                        report.results.sound.leftSpeakerResult
                      }}</span>
                  </div>
                </v-card>
                <v-card outlined class="d-flex py-2 px-4 align-center my-2 ml-2">
                  <div class="d-flex align-center">
                    <v-icon size="20" class="mt-1">mdi-ear-hearing</v-icon>
                    <span
                        :class="['subtitle-2', 'ml-2', {'text--disabled': report.results.sound.rightSpeakerResult === 'Not installed'}]">Right Speaker Test | {{
                        report.results.sound.rightSpeakerResult
                      }}</span>
                  </div>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="panel-border">
              <v-expansion-panel-header class="text-uppercase font-weight-medium">
                <span>Money | <span
                    :class="[colors[`${report.results.money.result}`].text, 'text-capitalize font-weight-regular']">{{
                    report.results.money.result
                  }} </span><v-icon
                    :color="colors[`${report.results.money.result}`].color">{{
                    icons[`${report.results.money.result}`]
                  }}</v-icon> </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-2">
                <span
                    class="grey--text">Detected Money Devices ({{
                    report.results.money.acceptors && report.results.money.acceptors.length
                  }}) [{{ report.results.money.detectedMoneyService }}]</span>
                <div v-for="(acceptor, i) in report.results.money.acceptors" v-bind:key="i" class="d-flex flex-column ">
                  <v-card outlined class="d-flex pa-4 align-start my-2">
                    <v-icon size="20" class="mt-1">{{ acceptor.type === 'coin' ? 'mdi-hand-coin' : 'mdi-cash' }}
                    </v-icon>
                    <div class="d-flex flex-column ml-2">
                      <span class="subtitle-2">{{
                          acceptor.type === 'coin' ? 'Coin Acceptor' : 'Bill Acceptor'
                        }} - {{ acceptor.model }}</span>
                      <span class="caption">{{ acceptor.protocol.toUpperCase() }}</span>
                    </div>
                  </v-card>
                  <p class="align-self-end caption">S/N: {{ acceptor.serialNumber }}</p>
                  <div class="scrollbar">

                    <v-card outlined class="d-flex py-2 px-4 align-center my-2 ml-2 "
                            v-for="(denomination, i) in acceptor.denominations" v-bind:key="i">
                      <div class="d-flex align-center">
                        <v-icon size="20" class="mt-1">
                          {{ acceptor.type === 'coin' ? 'mdi-circle-multiple' : 'mdi-cash' }}
                        </v-icon>
                        <span
                            :class="['subtitle-2', 'ml-2', {'text--disabled': denomination.testResult === 'Skipped'}]">
                        {{ denomination.denomination }} | <span :class="`${colors[denomination.testResult].text}`">{{
                            denomination.testResult
                          }}</span>
                      </span>
                      </div>
                    </v-card>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="panel-border">
              <v-expansion-panel-header class="text-uppercase font-weight-medium">
                <span>Door switches | <span
                    :class="[colors[`${report.results.doorSwitches.result}`].text, 'text-capitalize font-weight-regular']">{{
                    report.results.doorSwitches.result
                  }} </span><v-icon
                    :color="colors[`${report.results.doorSwitches.result}`].color">{{
                    icons[`${report.results.doorSwitches.result}`]
                  }}</v-icon> </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-2">
                <span class="grey--text">Detected door switches (1)</span>
                <v-card outlined class="d-flex pa-4 align-start my-2">
                  <v-icon size="20" class="mt-1">mdi-usb-port</v-icon>
                  <div class="d-flex flex-column ml-2">
                    <span class="subtitle-2">{{ report.results.doorSwitches.detectedDoorSwitchDevice }}</span>
                    <span class="caption">TBD</span>
                  </div>
                </v-card>
                <v-card outlined class="d-flex py-2 px-4 align-center my-2 ml-2"
                        v-for="(test, i) in report.results.doorSwitches.switchesTest" v-bind:key="i">
                  <div class="d-flex align-center">
                    <v-icon size="20" class="mt-1">mdi-usb-port</v-icon>
                    <span
                        :class="['subtitle-2', 'ml-2', {'text--disabled': test.testResult === 'Not installed'}]">Switch number {{ test.switchNumber }} | {{
                        test.testResult
                      }}</span>
                  </div>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="panel-border">
              <v-expansion-panel-header class="text-uppercase font-weight-medium">
                <span>Card reader | <span
                    :class="[colors[`${report.results.cardReader.result}`].text, 'text-capitalize font-weight-regular']">{{
                    report.results.cardReader.result
                  }} </span><v-icon
                    :color="colors[`${report.results.cardReader.result}`].color">{{
                    icons[`${report.results.cardReader.result}`]
                  }}</v-icon> </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-2">
                <span class="grey--text">Detected card readers (1)</span>
                <v-card outlined class="d-flex pa-4 align-start my-2">
                  <v-icon size="20" class="mt-1">mdi-remote</v-icon>
                  <div class="d-flex flex-column ml-2">
                    <span class="subtitle-2">{{ report.results.cardReader.detectedCardReaderDevice }}</span>
                    <span class="caption">TBD</span>
                  </div>
                </v-card>
                <span class="float-right caption">S/N: {{ report.results.cardReader.cardReaderSN }}</span>

              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="panel-border" v-if="report.results.usb.usbTest !== null">
              <v-expansion-panel-header class="text-uppercase font-weight-medium">
                <span>USB | <span
                    :class="[colors[`${report.results.usb.result}`].text, 'text-capitalize font-weight-regular']">{{
                    report.results.usb.result
                  }} </span><v-icon
                    :color="colors[`${report.results.usb.result}`].color">{{
                    icons[`${report.results.usb.result}`]
                  }}</v-icon> </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-2">
                 <span class="grey--text">Detected USB  ({{
                    report.results.usb.usbTest.length
                  }})</span>
                <v-card outlined class="d-flex pa-4 align-start my-2"
                        v-for="(usb, i) in report.results.usb.usbTest" v-bind:key="i">
                  <v-icon size="20" class="mt-1">mdi-usb-port</v-icon>
                  <div class="d-flex flex-column ml-2">
                    <v-row>
                      <v-col class="ml-0 pl-2"><span class="subtitle-2">Bus:{{report.results.usb.usbTest[i].bus }}</span></v-col>
                      <v-col class="ml-0 pl-0"><span class="subtitle-2">Level:{{report.results.usb.usbTest[i].level}}</span></v-col>
                      <v-col class="ml-0 pl-0"><span class="subtitle-2">Port:{{report.results.usb.usbTest[i].port }}</span></v-col>
                    </v-row>
                    <span v-if="report.results.usb.usbTest[i].over_hub === true" class="caption">Over HUB</span>
                    <span v-else class="caption"></span>
                  </div>
                </v-card>

              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-else>
               <v-expansion-panel-header class="text-uppercase font-weight-medium">
                <span>USB | <span
                    :class="[colors[`Not installed`].text, 'text-capitalize font-weight-regular']">
                    Data not available
                  </span><v-icon
                    small
                    :color="colors['Not installed'].color">{{
                    icons['Not installed']
                  }}</v-icon> </span>
              </v-expansion-panel-header>
            </v-expansion-panel>
            <v-expansion-panel class="panel-border" v-show="report.results.led.result !== ''">
              <v-expansion-panel-header class="text-uppercase font-weight-medium">
                <span>LED | <span
                    :class="[colors[`${report.results.led.result}`].text, 'text-capitalize font-weight-regular']">{{
                    report.results.led.result
                  }} </span><v-icon
                    :color="colors[`${report.results.led.result}`].color">{{
                    icons[`${report.results.led.result}`]
                  }}</v-icon> </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-2">
                 <span class="grey--text">Led Panel</span>
                 <v-card outlined class="d-flex pa-4 align-start my-2">
                  <v-icon size="25" >mdi-led-outline</v-icon>
                  <div class="d-flex flex-column ml-2">
                    <v-row>
                      <v-col class="ml-0 mt-1 pl-2">
                        <span class="subtitle-2">Firmware version: {{ report.results.led.fwVer }}</span>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
                 <v-card outlined class="d-flex pa-4 align-start my-2">
                  <v-icon size="25">mdi-led-outline</v-icon>
                  <div class="d-flex flex-column ml-2">
                    <v-row>
                      <v-col class="ml-0 mt-1 pl-2">
                        <span class="subtitle-2">Model: {{ report.results.led.model  }}</span>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
                 <v-card outlined class="d-flex pa-4 align-start my-2">
                  <v-sheet class="color-box mt-1" :style="{ backgroundColor: computedColor }"></v-sheet>
                  <div class="d-flex flex-column ml-2">
                    <v-row class="pa-3">
                      <span class="subtitle-2"> Selected Color: {{ report.results.led.selectedColor }}</span>
                    </v-row>
                  </div>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row class="mx-2">
            <div class="my-10 report-overview pa-4" v-show="report.comment !== ''">
          <span><b>Comment :</b>  {{ report.comment }}</span>
            </div>
          </v-row>
        </div>
      </v-col>
      <v-divider
          class="d-none d-md-flex mt-3"
          vertical style="height: 100vh; z-index: 99 !important"/>
      <v-col
          cols="12"
          sm="12"
          md="4"
          class="ml-n3 mt-3 py-0 pr-0 content-height overflow-y-auto">
        <v-card

            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Report Date</span>
            <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
              {{ new Date(report.testTimestamp * 1000).toLocaleString() }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card

            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Tested By</span>
            <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
              {{ report.testedBy }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <!--                <v-card-->

        <!--                    height="72"-->
        <!--                    class="px-4 pb-5 pt-3 "-->
        <!--                    flat>-->
        <!--                  <v-card-text class="pa-0">-->
        <!--                    <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Associated Device</span>-->
        <!--                    <v-list-item-title class="subtitle-2">-->
        <!--                      <v-chip class="px-2  blue&#45;&#45;text font-weight-regular" style="height: initial">-->
        <!--                        <v-icon color="primary" class="mr-1">mdi-link</v-icon>-->
        <!--                        Terminal 1-->
        <!--                      </v-chip>-->
        <!--                    </v-list-item-title>-->
        <!--                  </v-card-text>-->
        <!--                </v-card>-->
        <!--                <v-divider/>-->
        <v-card
            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Report Outcome</span>
            <v-list-item-title class="subtitle-2">
              <v-chip :color="report.overallResult === 'PASSED' ? 'primary' : 'error'" class="white--text" small>
                {{ report.overallResult }}
              </v-chip>
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card

            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Device S/N</span>
            <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
              {{ report.deviceSN }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <!--        <v-card-->

        <!--            height="72"-->
        <!--            class="px-4 pb-5 pt-3 "-->
        <!--            flat>-->
        <!--          <v-card-text class="pa-0">-->
        <!--            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Computer S/N</span>-->
        <!--            <v-list-item-title class="subtitle-2 text--primary font-weight-regular">-->
        <!--              JHG7654-->
        <!--            </v-list-item-title>-->
        <!--          </v-card-text>-->
        <!--        </v-card>-->
        <!--        <v-divider />-->
        <v-card

            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Hardware ID</span>
            <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
              {{ report.deviceID }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card

            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
              <v-tooltip left color="#E0E0E0">
              <template v-slot:activator="{ on, attrs }">
                  <div  v-bind="attrs"
                    v-on="on">
                  <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">CPU</span>
                  <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
                      {{ report.CPU }}
                  </v-list-item-title>
                  </div>
              </template>
          <span class="text--primary font-weight-regular">CPU</span><br>
          <span class="text--primary font-weight-regular">{{ report.CPU }}</span>
          </v-tooltip>    
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card
            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <v-tooltip left color="#E0E0E0">
              <template v-slot:activator="{ on, attrs }">
                  <div  v-bind="attrs"
                    v-on="on">
                  <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">GPU</span>
                  <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
                      {{ report.GPU }}
                  </v-list-item-title>
                  </div>
              </template>
          <span class="text--primary font-weight-regular">GPU</span><br>
          <span class="text--primary font-weight-regular">{{ report.GPU }}</span>
          </v-tooltip>    
          </v-card-text>
        </v-card>
        <v-divider/>
        <!--        <v-card-->

        <!--            height="72"-->
        <!--            class="px-4 pb-5 pt-3 "-->
        <!--            flat>-->
        <!--          <v-card-text class="pa-0">-->
        <!--            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Disk</span>-->
        <!--            <v-list-item-title class="subtitle-2 text--primary font-weight-regular">-->
        <!--              28 GB-->
        <!--            </v-list-item-title>-->
        <!--          </v-card-text>-->
        <!--        </v-card>-->
        <!--        <v-divider />-->
        <v-card

            height="72"
            class="px-4 pb-5 pt-3  overflow-y-auto"
            flat>
          <v-card-text class="pa-0">
            <v-tooltip left color="#E0E0E0">
              <template v-slot:activator="{ on, attrs }">
                  <div  v-bind="attrs"
                    v-on="on">
                  <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">RAM</span>
                  <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
                      {{ report.RAM }}
                  </v-list-item-title>
                  </div>
              </template>
          <span class="text--primary font-weight-regular">RAM</span><br>
          <span class="text--primary font-weight-regular">{{ report.RAM }}</span>
          </v-tooltip>    
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card

            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Report Type</span>
            <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
              {{ report.testMode ? 'Test Report' : 'Real Report' }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card

            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Disk</span>
            <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
              {{ report.disk }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card
            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">MB:</span>
            <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
              {{ report.MB }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card
            height="72"
            class="px-4 pb-5 pt-3 "
            flat>
          <v-card-text class="pa-0">
            <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Stt version:</span>
            <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
              {{ report.sttVersion }}
            </v-list-item-title>
          </v-card-text>
        </v-card>
        <v-divider/>
        <v-card
            height="72"
            class="px-4 pb-5 pt-3 d-flex align-center justify-center"
            flat>
          <v-card-text class="pa-0 ">
            <v-btn block color="lightGrey" large class="text-capitalize" style="letter-spacing: normal"
                   @click="getSlip(report.id)">
              <v-icon class="mr-1">mdi-file-document</v-icon>
              See Printed Report
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

/* NOTE:
  - Barcode scanner je uvijek USB.
  - Izbaciti port zvucne kartice
  - Speaker test - L/R - passed or failed
  - Ako nema zvucne, onda je not installed
*/
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Report',
  mounted() {
    this.countTests();
  },
  computed: {
    ...mapGetters({
      report: 'report/currentReport'
    }),
    computedColor() {
      // Your logic to compute the color based on other data properties
      // Example: return someCalculation(this.someData);
      return this.report.results.led.selectedColor || "#ffffff"; // Replace with your actual logic
    },
  },
  watch: {
    report: {
      handler() {
        console.log("Report changed");
        this.countTests();
      }
    }
  },
  data: () => ({
    notInstalled: [],
    passed: [],
    skipped: [],
    failed: [],
    icons: {
      "Not installed": "mdi-minus-circle",
      "Passed": "mdi-check-circle",
      "Skipped": "mdi-alert-circle",
      "Failed": "mdi-minus-circle",
    },
    colors: {
      "Not installed": {
        "text": "text--secondary",
        "color": "secondaryText"
      },
      "Passed": {
        "text": "crtBarJade--text",
        "color": "crtBarJade"
      },
      "Skipped": {
        "text": "warning--text",
        "color": "warning"
      },
      "Failed": {
        "text": "error--text",
        "color": "error"
      },
        "":{
          "text":"error--text",
          "color":"error"
        }
    }
  }),
  methods: {
    ...mapActions({
      getSlip: 'report/getSlip',
      selectReport: 'report/selectReport'
    }),
    closeReport() {
      this.$store.commit('report/SET_CURRENT_REPORT', '');
      this.$router.push('/reports');
    },
    capitalize(string) {
      return string[0].toUpperCase() + string.slice(1);
    },
    countTests() {
      this.countPassed()
      this.countNotInstalled()
      this.countSkipped()
      this.countFailed()
    },
    countPassed() {
      this.passed = [];
      let peripherals = Object.keys(this.report.results);
      peripherals.forEach(peripheral => {
        let keys = Object.keys(this.report.results[peripheral]);
        keys.forEach(key => {
          if (key.includes("result")) {
            if (this.report.results[peripheral][key] === "Passed") {
              this.passed.push(peripheral);
            }
          }
        })
      })
      console.log(this.passed.length)
    },
    countFailed() {
      this.failed = [];
      let peripherals = Object.keys(this.report.results);
      peripherals.forEach(peripheral => {
        let keys = Object.keys(this.report.results[peripheral]);
        keys.forEach(key => {
          if (key.includes("result")) {
            if (this.report.results[peripheral][key] === "Failed") {
              this.failed.push(peripheral);
            }
          }
        })
      })
    },
    countSkipped() {
      this.skipped = [];
      let peripherals = Object.keys(this.report.results);
      peripherals.forEach(peripheral => {
        let keys = Object.keys(this.report.results[peripheral]);
        keys.forEach(key => {
          if (key.includes("result")) {
            if (this.report.results[peripheral][key] === "Skipped") {
              this.skipped.push(peripheral);
            }
          }
        })
      })
    },
    countNotInstalled() {
      this.notInstalled = [];
      let peripherals = Object.keys(this.report.results);
      peripherals.forEach(peripheral => {
        let keys = Object.keys(this.report.results[peripheral]);
        keys.forEach(key => {
          if (key.includes("result")) {
            if (this.report.results[peripheral][key] === "Not installed") {
              this.notInstalled.push(peripheral);
            }
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.panel-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12)
}

::v-deep {
  .v-chip {
    height: 16px;
    border-radius: 4px;
    font-weight: bold;
  }
}

.report-overview {
  background: #EFF7FF;
  border: 1px solid #2B85D8;
  box-sizing: border-box;
  border-radius: 4px;
}

.w-100 {
  width: 100%;
}

.content-height {
  max-height: calc(100vh - 204px);
}

.report-container {
  .icon {
    border-radius: 4px !important;
    border: 1px solid #464343 !important;
  }

}

::-webkit-scrollbar {
  width: 0 !important;
}

.color-box {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  border: 2px solid #c4c2c2;
}
</style>
