<template>
  <div>
    <v-card flat class="feed-tests mt-2" outlined :to="/reports/+report.id" v-for="report in reports" v-bind:key="report.id">
      <v-card-title class="pa-3">
        <!-- Icon for Reports -->
        <v-avatar
            rounded
            size="64"
            v-bind:class="[
            report['overallResult'] ==='PASSED' ? 'pass' : report['overallResult'] ==='FAILED' ? 'fail': null,
          'item-icon',
        ]"
        >
          <img class="report-image" src="../assets/reportIcon.png" alt="report">
        </v-avatar>
        <!-- Info container reports-->
        <div class="reports-info">
          <v-row>
            <v-col>
              <v-chip 
                v-bind:class="[
                  report['overallResult'] ==='PASSED' ? 'pass' : report['overallResult'] ==='FAILED' ? 'fail': null,
              ]"
                label x-small pill class="ml-0 text--secondary"

                ><span>{{ report.overallResult }}</span>
              </v-chip>
            </v-col>
            <v-col v-show="report.testMode === true">
                <v-chip
                    x-small pill
                    color="red"
                    text-color="darken-4"
                >
                  Test Report
                </v-chip>
            </v-col>
          </v-row>
          <v-card-text class="px-0 py-0">
            <v-list-item-title
                class="ml-0  font-weight-medium letter-spacing-normal"
            >
              <span class="report-text">{{  report.deviceSN }}</span><br>
              <span class="date-text">{{ new Date(report.testTimestamp * 1000).toLocaleString() }}</span>
            </v-list-item-title>

          </v-card-text>
        </div>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['reports'],
  methods: {
    getColor(type) {
      var colors = {
        "PASSED": "#2B85D8",
        "FAILED": "#EC4234",
        "SKIPPED": "#F7B500",
      };
      return colors[type];
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .report-image {
    width: 20px;
    height: 24px;
  }

  .item-icon {
    padding: 20px;
    background-color: var(--v-primary-base);
    border-radius: 4px;
    border: solid 1px #464343;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .date-text {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;

  }

  .report-text {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }

  .v-chip {
    height: 16px;
    background-color: var(--v-primary-base)!important;
    border-radius: 4px !important;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: -1px;
  }

  .v-chip__content {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #FFFFFF;
  }

  .reports-info {
    margin-left: 16px;
    margin-top: -1px;
    text-overflow: ellipsis;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: calc(100% - 16px - 64px);
  }
  .pass {
    background-color: var(--v-primary-base)!important; //background color for icon
  }

  .fail {
    background-color: var(--v-error-base)!important;
  }
}
</style>
