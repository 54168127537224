<template>
  <div style="height:90vh;" class="scrollbar pa-4 mb-18">
    <v-col class="wraper-height mb-16" >
      <v-row class="px-1 pt-0 pb-4">
      <v-col class="d-inline">
        <v-icon color="blue darken-2">
          mdi-assistant
        </v-icon>
        <span class="subtitle-2 ml-2">Reports outcome</span>
        <v-checkbox
            class="mt-2"
            multiple
            dense
            v-model="values.report_outcome"
            v-for="(value, index) in filters.report_outcome.options"
            :key="index"
            :label="value.label"
            :value="value.value"
            hide-details
        />
      </v-col>
    </v-row>
    <v-divider class="mt-2"/>
    <v-row class="px-1 pt-8 pb-2">
      <v-col class="d-inline">
        <v-icon color="blue darken-2">
          mdi-assistant
        </v-icon>
        <span class="subtitle-2 ml-2">Report period</span>
        <span class="close-button subtitle-2 ml-2 pr-2">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  icon
                  small
                  color="darken-2"
                  class="ml-2"
                  @click="clearDate"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Clear filter</span>
          </v-tooltip>
          </span>
        <v-date-picker
            v-model="initial_date"
            class="mt-2"
            full-width
            no-title
            range
        ></v-date-picker>
      </v-col>
    </v-row>
    <v-divider class="mt-2"/>
    <v-row class="px-1 pt-8 pb-6">
      <v-col class="d-inline">
        <v-icon color="blue darken-2">
          mdi-assistant
        </v-icon>
        <span class="subtitle-2 ml-2">Reports Contains</span>
        <v-checkbox
            class="mt-2"
            multiple
            dense
            v-model="values.report_contains"
            v-for="(value, index) in filters.report_contains.options"
            :key="index"
            :label="value.label"
            :value="value.value"
            hide-details
        />
        <!---->
      </v-col>
    </v-row>
    <v-divider class="mt-2"/>
    <v-row class="px-1 pt-8 pb-6">
      <v-col class="d-inline">
        <v-icon color="blue darken-2">
          mdi-assistant
        </v-icon>
        <span class="subtitle-2 ml-2">Reports Type</span>
        <v-checkbox
            class="mt-2"
            multiple
            dense
            v-model="values.report_type"
            v-for="(value, index) in filters.report_type.options"
            :key="index"
            :label="value.label"
            :value="value.value"
            hide-details
        />
      </v-col>
    </v-row>
    <v-row class="px-4 pb-6">
      <v-btn color="primary" outlined style="letter-spacing: normal" class="text-capitalize" block
             @click="applyFilters" >Apply filters
      </v-btn>
    </v-row>
    <v-row class="px-4 pb-6 mb-16">
      <v-btn color="grey" outlined style="letter-spacing: normal" class="text-capitalize" block
             @click="clearFilterValues" >Clear Filters
      </v-btn>
    </v-row>
    </v-col>
    
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: 'FilterPanel',
  computed: {
    ...mapGetters({
      filters: 'report/filters'
    })
  },
  methods: {
    ...mapActions({
      applyReportFilters: "report/applyFilters"
    }),
    applyFilters() {
      this.applyReportFilters(this.values);
    },
    //clear date method 
    clearDate(){
      this.values.dates = "";
      this.initial_date = [];
      this.converted_date = [];
    },
    unixTimestamp(){
      for(let i in this.initial_date){
        //convert date to unix timestamp and get
        this.converted_date.push(new Date(this.initial_date[i]).getTime() / 1000);
    }
    if(this.converted_date.length > 2 ){
      //remove first value from values.dates
      this.converted_date.shift();
      this.values.dates = this.converted_date[0]+"%20"+this.converted_date[1];
    }
  },
  clearFilterValues(){
    this.values.report_contains = [];
    this.values.report_type = [];
    this.values.report_outcome = [];
    this.initial_date = [];
    this.converted_date = [];
    this.values.dates = "";
    this.applyReportFilters(this.values);
  },
  },
  watch:{
    initial_date(){
      this.unixTimestamp();
    }
  },
  data: () => ({
    initial_date: [],
    converted_date: [],
    values: {
      report_contains: [],
      report_type: ["real_report"],
      report_outcome: [],
      dates: "",
    },
  })
}
</script>

<style lang="scss"
       scoped>
.scrollbar {
  background-color: #fff !important;
  height: calc(100% - 164px);
  overflow-y: scroll; /* Add the ability to scroll */
  overflow-x: hidden;
}
.wraper-height {
  height: 200px;
}

::v-deep {
  .v-date-picker-header {
    padding: 4px 0;
  }

  .v-date-picker-table {
    padding: 0;
  }

  .v-skeleton-loader__list-item-avatar .v-skeleton-loader__avatar {
    height: 18px;
    width: 18px;
    border-radius: 4px;
  }

  .v-skeleton-loader__list-item-avatar {
    padding: 0 32px 0 0;
  }

  .v-skeleton-loader__list-item {
    height: 16px;
    padding: 0 56px 0 0;
  }

  .close-button{
    float: right;
  }

  label.v-label.theme--light {
    font-size: 14px;
  }
  .scrollbar {
    background-color: #fff !important;
    height: 1000px;
    overflow-y: auto; /* Add the ability to scroll */
    overflow-x: hidden;
  }

}
</style>
